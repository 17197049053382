import { PATHS } from "@/constants";
import type { Menu } from "./types";
import IconCar from "@/components/Icons/IconCar";

export const menu: Menu[] = [
  {
    icon: IconCar,
    title: "Автосервисы",
    path: PATHS.SERVICES,
  },
];
