import { useParams } from "react-router";
import { getEnv } from "@/utils/env";

const DomainPage = () => {
  const { domainName } = useParams();

  return <a href={`https://${domainName}.pro1.one`}>Перейти на сайте</a>;
};

export default DomainPage;
