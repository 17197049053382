import PageTitle from "@/components/PageTitle";
import type { HeaderProps } from "./types";
import Button from "@/components/UI/Button";
import styles from "./styles.module.scss";
import { isValidElement, PropsWithChildren } from "react";

const Header = ({
  actions,
  children,
  showSearch = true,
  title,
}: PropsWithChildren<HeaderProps>) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <PageTitle title={title} />
        <div className={styles.headerRight}>
          {children}
          {isValidElement(actions) && (
            <div className={styles.actions}>{actions}</div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
