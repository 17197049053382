import { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { useNavigate } from "react-router";
import Input from "@/components/UI/Input";
import Button from "@/components/UI/Button";
import { registerUser } from "../../services";
import { PATHS } from "@/constants";
import { registerFormSchema } from "./schema";
import { RegisterFormData } from "../../types";
import { FormFields, roles } from "../../constants";
import { NavLink } from "react-router-dom";
import Select from "@/components/UI/Select";
import styles from "./styles.module.scss";
import { toast } from "react-toastify";

const initialValues = {
  domainName: "",
  email: "",
  firstName: "",
};

const RegisterForm = () => {
  const [isFetching, setIsFetching] = useState(false);

  const handleAuth = async (
    values: RegisterFormData,
    { resetForm }: FormikHelpers<RegisterFormData>,
  ) => {
    setIsFetching(true);

    try {
      await registerUser(values);
      resetForm();
      toast.success("Вы зарегистрировались");
    } catch (err) {
      toast.error("Ошибка регистрации");
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleAuth}
        validationSchema={registerFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, setFieldValue }) => (
          <Form className={styles.form}>
            <h1 className={styles.formTitle}>Регистрация</h1>
            <Input
              error={errors[FormFields.FirstName]}
              label="Имя"
              disabled={isFetching}
              name={FormFields.FirstName}
              type="text"
              placeholder="Введите имя"
              onChange={handleChange}
              value={values.firstName}
            />
            <Input
              error={errors[FormFields.Email]}
              label="Email"
              disabled={isFetching}
              name={FormFields.Email}
              type="text"
              placeholder="Введите email"
              onChange={handleChange}
              value={values.email}
            />
            <Input
              error={errors[FormFields.DomainName]}
              label="Поддомен"
              disabled={isFetching}
              name={FormFields.DomainName}
              type="text"
              placeholder="Введите поддомен"
              onChange={handleChange}
              value={values.domainName}
            />

            <Button disabled={isFetching} type="submit">
              Зарегистрироваться
            </Button>
          </Form>
        )}
      </Formik>
      <NavLink className={styles.authLink} to={PATHS.AUTH}>
        Войти
      </NavLink>
    </div>
  );
};

export default RegisterForm;
