export const PATHS = {
  AUTH: "/auth",
  CREATE_SUBDOMAIN: "/create-subdomain",
  DOMAIN: "/domain",
  REGISTER: "/register",
  SERVICES: "/",
};

export const AUTH_TOKEN_COOKIE_NAME = "authToken";

export enum EVENTS {
  AUTH_ERROR = "authError",
  REFRESH_DATA = "refreshData",
}
