const IconClose = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.94187 13.4824L13.7096 4.71471C14.0091 4.41515 14.506 4.41515 14.8055 4.71471C15.1051 5.01428 15.1051 5.51111 14.8055 5.81068L6.03783 14.5784C5.73827 14.8779 5.24143 14.8779 4.94187 14.5784C4.64231 14.2788 4.64231 13.782 4.94187 13.4824Z"
        fill="#7C7A85"
      />
      <path
        d="M4.94187 4.71459C5.24143 4.41503 5.73827 4.41503 6.03783 4.71459L14.8055 13.4823C15.1051 13.7819 15.1051 14.2787 14.8055 14.5783C14.506 14.8778 14.0091 14.8778 13.7096 14.5783L4.94187 5.81055C4.64231 5.51099 4.64231 5.01415 4.94187 4.71459Z"
        fill="#7C7A85"
      />
    </svg>
  );
};

export default IconClose;
