import IconAvatar from "@/components/Icons/IconAvatar";

import { useAppSelector } from "@/store";
import { selectUser } from "@/store/slices/userSlice";
import { UserType } from "@/types";
import { getUserFullName } from "@/utils/common";
import styles from "./styles.module.scss";

const UserInfo = () => {
  const user = useAppSelector(selectUser) as UserType;

  return (
    <div className={styles.userInfo}>
      {/*{Boolean(user?.avatar?.link) ? (*/}
      {/*  <img src={user.avatar?.link} alt="Аватарка" />*/}
      {/*) : (*/}
      {/*  <IconAvatar />*/}
      {/*)}*/}
      <IconAvatar />
      <span className={styles.userInfoName}>
        {getUserFullName(user) || "Без имени"}
      </span>
    </div>
  );
};

export default UserInfo;
