import { v4 } from "uuid";
import cn from "classnames";
import { PasswordInputProps } from "./types";
import IconButton from "@/components/UI/IconButton";
import IconEye from "@/components/Icons/IconEye";
import IconEyeSlash from "@/components/Icons/IconEyeSlash";
import { useState } from "react";
import styles from "./styles.module.scss";

const PasswordInput = (props: PasswordInputProps) => {
  const { id = v4(), error, label, maxLength, variant } = props;
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const handleToggleVisiblePassword = () => {
    setVisiblePassword((prev) => !prev);
  };

  return (
    <label className={styles.label} htmlFor={id}>
      {Boolean(label) && label}
      <div className={styles.content}>
        <input
          id={id}
          className={cn(styles.input, {
            [styles.inputInvalid]: Boolean(error),
            [styles.inputSmall]: variant === "small",
          })}
          {...props}
          type={visiblePassword ? "text" : "password"}
        />
        <IconButton
          className={styles.btn}
          icon={visiblePassword ? IconEyeSlash : IconEye}
          label=""
          onClick={handleToggleVisiblePassword}
        />
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </label>
  );
};

export default PasswordInput;
