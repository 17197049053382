import api from "@/api";
import { ResponseFromService } from "@/types";
import { serviceSerialize, servicesSerialize } from "./serializer";
import {
  CarServiceOwnerEntity,
  CreateServiceService,
  GetServiceService,
  GetServicesRequestPayload,
  GetServicesService,
} from "./types";

export const createService: CreateServiceService = async (body) => {
  const response = await api.post("/car-service-owner/register", body);
  return response.data;
};

export const getServices: GetServicesService = async ({
  pageNumber,
  pageSize,
}) => {
  const params: GetServicesRequestPayload = {
    pageNumber: pageNumber || 1,
    pageSize: pageSize || 20,
  };

  const response = await api.get<ResponseFromService<CarServiceOwnerEntity>>(
    "/car-service-owner",
    {
      params,
    },
  );

  return servicesSerialize(response.data);
};

export const getService: GetServiceService = async (id: string) => {
  const response = await api.get<CarServiceOwnerEntity>(
    `/car-service-owner/${id}`,
  );

  return serviceSerialize(response.data);
};

export const removeService = async (id: string) => {
  const response = await api.delete(`/car-service-owner/${id}`);
  return response.data;
};
