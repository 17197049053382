import api from "@/api";
import { UserType } from "@/types";
import { RegisterResponse, RegisterService } from "./types";

export const registerUser: RegisterService = async (credentials) => {
  const response = await api.post<RegisterResponse>(
    "/car-service-owner/register",
    credentials,
  );
  return response.data;
};
