import ReactSelect, {
  components,
  DropdownIndicatorProps,
  Props,
  GroupBase,
} from "react-select";
import IconLoading from "@/components/Icons/IconLoading";
import type { ReactNode } from "react";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import getStyles from "./styles";
import type { SelectProps } from "./types";
import s from "./styles.module.scss";

const IndicatorsContainer = ({ children }: { children: ReactNode }) => (
  <div>{children}</div>
);

const LoadingIndicator = () => <IconLoading stroke="red" />;

const defaultNoOptionsMessage = () => "Ничего не найдено";

const Select = function <
  Option,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<Option> = GroupBase<Option>,
>({
  id = "",
  invalid = false,
  errorMessageId = "",
  descriptionId,
  menuPlacement,
  isRounded = false,
  label,
  error,
  noOptionsMessage = defaultNoOptionsMessage,
  ...props
}: Props<Option, IsMulti, GroupType> & SelectProps) {
  const styles = getStyles<Option, IsMulti, GroupType>(isRounded);

  const DropdownIndicator = (
    props: DropdownIndicatorProps<Option, IsMulti, GroupType>,
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconArrowDown />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className={s.container}>
      {Boolean(label) && <label className={s.label}>{label}</label>}
      <ReactSelect
        {...props}
        aria-describedby={descriptionId}
        aria-errormessage={errorMessageId}
        aria-invalid={invalid}
        styles={styles}
        menuPlacement="auto"
        components={{
          IndicatorsContainer,
          DropdownIndicator,
          LoadingIndicator,
        }}
        inputId={id}
        noOptionsMessage={noOptionsMessage}
      />
      {Boolean(error) && <p className={s.error}>{error}</p>}
    </div>
  );
};

export default Select;
