import api from "@/api";
import { UserType } from "@/types";
import {
  AuthResponse,
  AuthService,
  RegisterService,
} from "@/features/Auth/types";

export const loginUser: AuthService = async (credentials) => {
  const response = await api.post<UserType>("/auth/login", credentials);
  return response.data;
};

export const registerUser: RegisterService = async (credentials) => {
  const response = await api.post<AuthResponse>("/auth/register", credentials);
  return response.data;
};
