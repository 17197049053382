import { useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import Input from "@/components/UI/Input";
import Button from "@/components/UI/Button";
import PasswordInput from "@/components/UI/PasswordInput";
import { setAuthToken } from "@/api";
import { loginUser } from "../../services";
import { useAppDispatch } from "@/store";
import { setUser } from "@/store/slices/userSlice";
import { authFormSchema } from "./schema";
import { AuthFormData } from "../../types";
import { FormFields } from "../../constants";
import { toast } from "react-toastify";
import { PATHS } from "@/constants";
import styles from "./styles.module.scss";

const initialValues = {
  email: "",
  password: "",
};

const AuthForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const handleAuth = async (values: AuthFormData) => {
    setIsFetching(true);

    try {
      const user = await loginUser(values);
      dispatch(setUser(user));
      setAuthToken(user.token as string);
      toast.success("Вы вошли в систему");
      //navigate(getRedirectPath(user));
      navigate(PATHS.SERVICES);
    } catch (err) {
      toast.error("Ошибка авторизации");
      setIsFetching(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleAuth}
        validationSchema={authFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => (
          <Form className={styles.form}>
            <h1 className={styles.formTitle}>Авторизация</h1>
            <Input
              error={errors.email}
              name={FormFields.Email}
              type="text"
              placeholder="Логин"
              onChange={handleChange}
              value={values.email}
            />
            <PasswordInput
              error={errors.password}
              name={FormFields.Password}
              type="password"
              placeholder="Введите пароль"
              onChange={handleChange}
              value={values.password}
            />
            <Button disabled={isFetching} isLoading={isFetching} type="submit">
              Авторизоваться
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuthForm;
