import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import * as patterns from "@/utils/validationPatterns";
import { CarOwnerFormData } from "@/features/CarOwner/types";

// @ts-ignore
export const carOwnerFormSchema: Yup.ObjectSchema<CarOwnerFormData> =
  Yup.object().shape({
    email: Yup.string()
      .matches(patterns.emailRegex, messages.validEmail)
      .required(messages.required),
    domainName: Yup.string().required(messages.required),
    firstName: Yup.string().required(messages.required),
  });
