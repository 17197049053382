import { v4 } from "uuid";
import cn from "classnames";
import type { InputProps } from "./types";
import styles from "./styles.module.scss";

const Input = (props: InputProps) => {
  const { id = v4(), error, label, maxLength, variant } = props;

  return (
    <label className={styles.label} htmlFor={id}>
      {Boolean(label) && label}
      <input
        id={id}
        className={cn(styles.input, {
          [styles.inputInvalid]: Boolean(error),
          [styles.inputSmall]: variant === "small",
        })}
        {...props}
        type="text"
      />
      {error && <p className={styles.error}>{error}</p>}
    </label>
  );
};

export default Input;
