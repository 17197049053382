import { RootAccountRoleEnum } from "@/types";

export enum FormFields {
  DomainName = "domainName",
  Email = "email",
  Password = "password",
  FirstName = "firstName",
}

export const roles = [
  {
    label: RootAccountRoleEnum.Admin,
    value: RootAccountRoleEnum.Admin,
  },
  {
    label: RootAccountRoleEnum.CarServiceOwner,
    value: RootAccountRoleEnum.CarServiceOwner,
  },
];
