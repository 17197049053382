import * as Yup from "yup";
import * as messages from "@/utils/validationMessages";
import * as patterns from "@/utils/validationPatterns";
import type { RegisterFormData } from "../../types";

// @ts-ignore
export const registerFormSchema: Yup.ObjectSchema<RegisterFormData> =
  Yup.object().shape({
    domainName: Yup.string().required(messages.required),
    email: Yup.string()
      .matches(patterns.emailRegex, messages.validEmail)
      .required(messages.required),
    firstName: Yup.string().required(messages.required),
  });
