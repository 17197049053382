import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import Input from "@/components/UI/Input";
import Button from "@/components/UI/Button";
import { useAppSelector } from "@/store";
import { carOwnerFormSchema } from "./schema";
import { CarOwnerFormData } from "@/features/CarOwner/types";
import { FormFields } from "@/features/CarOwner/constants";
import { registerCarServiceOwner } from "@/features/CarOwner/services";
import { selectUser } from "@/store/slices/userSlice";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";

const initialValues = {
  email: "",
  domainName: "",
  firstName: "",
};

const CarOwnerForm = () => {
  const currentUser = useAppSelector(selectUser);
  const [isFetching, setIsFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState(() => initialValues);

  useEffect(() => {
    currentUser?.email &&
      setUser((prev) => ({ ...prev, email: currentUser.email }));
  }, []);

  const handleRegister = async (values: CarOwnerFormData) => {
    setIsFetching(true);

    try {
      await registerCarServiceOwner(values);
      toast.success("Регистрация завершена");
      setSuccess(true);
    } catch (err) {
      toast.error("Ошибка регистрации");
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={user}
        onSubmit={handleRegister}
        validationSchema={carOwnerFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => (
          <Form className={styles.form}>
            <h1 className={styles.formTitle}>Регистрация поддомена</h1>
            <Input
              disabled={isFetching}
              error={errors[FormFields.FirstName]}
              label="Имя"
              name={FormFields.FirstName}
              type="text"
              placeholder="Введите имя"
              onChange={handleChange}
              value={values.firstName}
            />
            <Input
              disabled={isFetching}
              error={errors[FormFields.Email]}
              label="Email"
              name={FormFields.Email}
              type="email"
              placeholder="Введите email"
              onChange={handleChange}
              value={values.email}
            />
            <Input
              disabled={isFetching}
              error={errors[FormFields.Domain]}
              label="Поддомен"
              name={FormFields.Domain}
              type="text"
              placeholder="Введите поддомен"
              onChange={handleChange}
              value={values.domainName}
            />
            <Button disabled={isFetching} type="submit">
              Зарегистрироваться
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CarOwnerForm;
