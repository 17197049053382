import cn from "classnames";
import type { FlexProps } from "./types";
import type { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

const Flex = ({
  children,
  className,
  columnGap = "md",
  ...styleProps
}: PropsWithChildren<FlexProps>) => {
  return (
    <div
      style={styleProps}
      className={cn(styles.flex, styles[`column-gap-${columnGap}`], className)}
    >
      {children}
    </div>
  );
};

export default Flex;
