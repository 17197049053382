import AuthForm from "@/features/Auth/components/AuthForm";
import { AuthPageProps } from "@/pages/types";

const AuthPage = ({ isAuthenticated }: AuthPageProps) => {
  if (isAuthenticated) {
    return <p>Вы авторизованы</p>;
  }

  return <AuthForm />;
};

export default AuthPage;
