export const addressRequired = "Необходимо выбрать населенный пункт";
export const validDate = "Введите корректную дату";
export const validEmail = "Введите корректный email";
export const validNumber = "Введите корректный номер";
export const validPhone = "Введите корректный номер телефона";
export const validPhoneStartsWithSeven =
  "Номер телефона должен начинаться с +7";
export const cyrillic = "Поле может содержать только кириллицу";
export const cyrillicAndNumber =
  "Поле может содержать только кириллицу и цифры";
export const cyrillicAndLatin =
  "Поле может содержать только кириллицу или латиницу";
export const docSize = "Размер документа не может быть более 5 мб";
export const docType = "Некорректный формат документа";
export const manufactureYear = "Дата выпуска не может быть ранее 1991 года";
export const maxChars = "Введите не более 255 символов";
export const minChars = "Введите не менее двух символов";
export const monthCard = "Некорректный формат месяца от 1 до 12";
export const numeric = "Это поле может содержать только цифры";
export const numericDelta = "Это поле может содержать только цифры";
export const passwordLength = "Пароль должен содержать не менее 8 символов";
export const passwordMatch = "Пароли не совпадают";
export const percent = "Введите значение от 0 до 100";
export const notEqualToZero = "Значение не должно равняться 0";
export const adultAge = "Возраст должен быть не менее 18 лет";
export const fullName = "Некорректный формат ФИО";
export const required = "Это поле обязательно для заполнения";
export const yearCard = "Проверьте введенные данные";
export const nameAndSurname = "Необходимо указать имя и фамилию";
export const correspondentAccount = "Проверьте введенный номер счета";
export const ogrn = "Проверьте введенный ОГРН";
export const kpp = "Проверьте введенный КПП";
export const bik = "Проверьте введенный БИК";
export const price = "Введите корректную сумму";
export const note = "Не может быть более 255";
export const positiveNumber = "Число должно быть положительным";
export const integer = "Поле может содержать только целые числа";
