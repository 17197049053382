import { UserType } from "@/types";

export const getUserFullName = (
  user: UserType | null,
  useMiddleName = true,
) => {
  if (!user) return;

  const { lastName, firstName } = user;

  const data = [lastName, firstName];

  if (useMiddleName) {
    data.push(user?.middleName);
  }

  return data.filter(Boolean).join(" ");
};

export const getObjectFromFullName = (fullName: string) => {
  const [lastName, firstName, middleName] = fullName?.trim()?.split(" ");
  return { lastName, firstName, middleName };
};
