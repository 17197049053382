import { AppLayoutProps } from "./types";
import { PropsWithChildren } from "react";
import Sidebar from "@/components/Sidebar";
import styles from "./styles.module.scss";

const AppLayout = ({ children }: PropsWithChildren<AppLayoutProps>) => {
  return (
    <div className={styles.appLayout}>
      <Sidebar />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default AppLayout;
