import {
  StylesConfig,
  GroupBase,
  ControlProps,
  OptionProps,
} from "react-select";

function getStyles<
  Option,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<Option> = GroupBase<Option>
>(isRounded: boolean): StylesConfig<Option, IsMulti, GroupType> {
  const getControlBorderColor = (
    props: ControlProps<Option, IsMulti, GroupType>
  ) => {
    let color = "var(--color-bright-tertiary)";

    if (props.selectProps["aria-invalid"]) {
      color = "var(--color-danger)";
    }

    if (props.isFocused) {
      color = "var(--color-bright-tertiary)";
    }

    return color;
  };
  const getOptionBackgroundColor = (
    props: OptionProps<Option, IsMulti, GroupType>
  ) => {
    let color = "transparent";

    if (props.isFocused) {
      color = "var(--color-primary-10)";
    }

    if (props.isSelected) {
      color = "var(--color-primary-40)";
    }

    if (props.isDisabled) {
      color = "var(--color-grey-40)";
    }

    return color;
  };

  return {
    container: (base) => ({
      ...base,
      fontFamily: "inherit",
      fontWeight: 400,
    }),
    control: (base, props) => ({
      ...base,
      backgroundColor: props.isDisabled
        ? "var(--color-grey-20)"
        : "var(--color-white)",
      borderWidth: "thin",
      borderStyle: "solid",
      borderColor: getControlBorderColor(props),
      borderRadius: "8px",
      boxShadow: "none",
      font: "inherit",
      height: "48px",
      width: "100%",
      padding: "0 0 0 16px",
      outline: "0",
      transition: "border 0.2s",
      cursor: "pointer",

      ":hover": {
        borderColor: getControlBorderColor(props),
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    valueContainer: (base, props) => ({
      ...base,
      color: "var(--color-text-bright-tertiary)",
      padding: 0,
      font: "inherit",
      fontSize: "14px",
      textOverflow: "ellipsis",
    }),
    placeholder: (base) => ({
      ...base,
      color: "var(--color-text-bright-tertiary)",
      font: "inherit",
      margin: 0,
      padding: 0,
    }),
    singleValue: (base) => ({
      ...base,
      color: "var(--color-text-bright-tertiary)",
      margin: 0,
      padding: 0,
    }),
    input: (base) => ({
      ...base,
      color: "var(--color-text-bright-tertiary)",
      margin: 0,
      padding: 0,
    }),
    dropdownIndicator: (base, props) => ({
      ...base,
      fontSize: "0.5rem",
      transition: "color 0.2s",

      "&, &:hover": {
        color: "var(--color-text-bright-tertiary)",
      },
    }),
    menu: (base) => ({
      ...base,
      color: "var(--color-text-bright-tertiary)",
      borderRadius: "0 0 8px 8px",
      marginTop: "0",
      boxShadow: "0px 16px 32px 0px rgba(9, 13, 17, 0.1)",
      maxHeight: "30rem",
      overflow: "auto",
      zIndex: 100,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    option: (base, props) => ({
      ...base,
      color: "var(--color-text-bright-tertiary)",
      backgroundColor: getOptionBackgroundColor(props),
      transition: "background 0.2s",
      font: "inherit",
      fontSize: "14px",
      fontWeight: 400,
      height: "40px",
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid var(--color-bright-secondary)",
      cursor: "pointer",
    }),
  };
}

export default getStyles;
