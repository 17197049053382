import TableEmpty from "./TableEmpty";
import TableRow from "./TableRow";
import TableCell from "./TableCell";
import cn from "classnames";
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
} from "react";
import type { TableProps, TableRowProps } from "./types";
import IconLoading from "@/components/Icons/IconLoading";
import styles from "./styles.module.scss";

const Table = ({
  className,
  children,
  header,
  isEmpty,
  isFetching,
  footer,
}: PropsWithChildren<TableProps>) => {
  const enhancedHeader = Children.map(header, (child) => {
    if (!isValidElement(child) || child.type !== TableRow) {
      return null;
    }

    return cloneElement(child, {
      variant: "header",
    } as TableRowProps);
  });

  return (
    <div className={cn(styles.wrapper, className)}>
      {Boolean(isFetching) && (
        <div className={styles.tableLoader}>
          <IconLoading width={24} height={24} />
        </div>
      )}

      <table className={styles.table}>
        {Array.isArray(enhancedHeader) && (
          <thead className={styles.header}>{enhancedHeader}</thead>
        )}
        <tbody>{children}</tbody>
      </table>

      {isEmpty && !isFetching && <TableEmpty />}

      {Boolean(footer) && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};

Table.Row = TableRow;
Table.Cell = TableCell;

export default Table;
