export const serviceInitialValue = {
  domainName: "",
  email: "",
};

export enum FormFields {
  CompanyName = "companyName",
  DomainName = "domainName",
  Email = "email",
  FirstName = "firstName",
  LastName = "lastName",
  MiddleName = "middleName",
  FullName = "fullName",
  Phone = "phone",
}
