import { Route, Routes } from "react-router-dom";
import PrivateRoute from "@/components/PrivateRoute";
import UnauthorizedLayout from "@/layouts/UnauthorizedLayout";
import AuthPage from "@/pages/AuthPage";
import RegisterPage from "@/pages/RegisterPage";
import DomainPage from "@/pages/DomainPage";
import CarOwner from "@/pages/CarOwner";
import { PATHS } from "@/constants";
import { useAppSelector } from "@/store";
import { selectIsAuthenticated, selectUser } from "@/store/slices/userSlice";
import NotFoundPage from "@/pages/NotFoundPage";
import ServicesPage from "@/pages/ServicesPage";

const Pages = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return (
    <Routes>
      <Route
        path={PATHS.AUTH}
        element={
          <UnauthorizedLayout>
            <AuthPage isAuthenticated={Boolean(isAuthenticated)} />
          </UnauthorizedLayout>
        }
      />
      <Route
        path={PATHS.REGISTER}
        element={
          <UnauthorizedLayout>
            <RegisterPage />
          </UnauthorizedLayout>
        }
      />
      <Route
        path={PATHS.DOMAIN}
        element={
          <PrivateRoute>
            <DomainPage />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.SERVICES}
        element={
          <PrivateRoute>
            <ServicesPage />
          </PrivateRoute>
        }
      />
      <Route
        path={PATHS.CREATE_SUBDOMAIN}
        element={
          <PrivateRoute>
            <CarOwner />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <UnauthorizedLayout>
            <NotFoundPage />
          </UnauthorizedLayout>
        }
      />
    </Routes>
  );
};

export default Pages;
