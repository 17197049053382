import { ResponseFromService } from "@/types";
import { CarServiceOwnerEntity } from "./types";
import { getObjectFromFullName, getUserFullName } from "@/utils/common";

export const servicesSerialize = (
  data: ResponseFromService<CarServiceOwnerEntity>,
) => ({
  items: data.items,
  pagination: {
    pageNumber: data.pagination?.pageNumber,
    pageSize: data.pagination?.pageSize,
    totalItems: data.pagination?.totalItems,
    totalPages: data.pagination?.totalPages,
  },
});

export const serviceSerialize = (service: CarServiceOwnerEntity) => ({
  ...service,
  email: service?.account?.email,
  fullName: getUserFullName(service?.account),
});
