export type EntityType = "legal" | "individual";

export type RoleType = "admin" | "car-service-owner";

export interface CarServiceOwnerEntity {
  account: UserType;
  domainName: string;
}

export interface FileEntity {
  name: string;
  link: string;
  mimeType: string;
  size: number;
}

export interface RootAccountEntity {
  _id: string;
  email: string;
  passwordHash: string;
  recoveryToken?: string;
  refreshToken?: string;
  role: RootAccountRoleEnum;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  avatar?: FileEntity;
}

export enum RootAccountRoleEnum {
  Admin = "admin",
  CarServiceOwner = "car-service-owner",
}

export type UserType = {
  _id: string;
  email: string;
  passwordHash: string;
  recoveryToken?: string;
  refreshToken?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  avatar?: FileEntity;
  balance: number;
  token?: string;
  entityType: EntityType;
  role: RootAccountRoleEnum;
  owner: CarServiceOwnerEntity | null;
};

export interface ResponseFromService<T> {
  items: T[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  };
}
