import * as Yup from "yup";
import * as expect from "@/utils/validationExpect";
import * as messages from "@/utils/validationMessages";
import { FormFields } from "./constant";
import { CarServiceOwnerEntity } from "../../types";

// @ts-ignore
export const serviceFormSchema: Yup.ObjectSchema<CarServiceOwnerEntity> =
  Yup.object().shape({
    [FormFields.Email]: Yup.string()
      .email(messages.validEmail)
      .required(messages.required),
    [FormFields.DomainName]: Yup.string().required(messages.required),
    [FormFields.CompanyName]: Yup.string().required(messages.required),
    [FormFields.FirstName]: Yup.string().trim().required(messages.required),
    [FormFields.Phone]: Yup.string().test(
      "phone",
      messages.validNumber,
      expect.phoneNumber,
    ),
  });
