import cn from "classnames";
import type { TableCellProps } from "./types";
import type { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

const TableCell = ({
  children,
  className,
  ...props
}: PropsWithChildren<TableCellProps>) => {
  return (
    <td {...props} className={cn(styles.cell, className)}>
      {children}
    </td>
  );
};

export default TableCell;
