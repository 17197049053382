import Table from "@/components/UI/Table";
import { Dropdown } from "rsuite";
import { useNavigate } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { If } from "@/components/ConditionalRendering/If";
import Pagination from "@/components/UI/Pagination";
import { CarServiceOwnerEntity } from "../../types";
import { getUserFullName } from "@/utils/common";
import { getServices } from "@/features/Service/services";
import IconMore from "@/components/Icons/IconMore";
import Flex from "@/components/UI/Flex";
import useOnce from "@/hooks/useOnce";
import { ServiceTableListProps } from "./types";
import { EVENTS } from "@/constants";
import styles from "./styles.module.scss";

const ServiceTableList = ({ onEdit, onRemove }: ServiceTableListProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [services, setServices] = useState<CarServiceOwnerEntity[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isFetching, setIsFetching] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get("page")) || 1;
  const pageSize = Number(queryParams.get("pageSize")) || 20;

  const replaceQuery = () => {
    navigate({
      ...location,
      search: String(queryParams),
    });
  };

  const handleGetServices = useCallback(
    () =>
      getServices({
        pageNumber: page,
        pageSize,
      })
        .then((data) => {
          setServices(data.items);
          setTotalPages(data.pagination.totalPages || 1);
          setTotalItems(data.pagination.totalItems || 0);
        })
        .catch(() => {
          setServices([]);
          setTotalPages(1);
          setTotalItems(0);
        })
        .finally(() => {
          setIsFetching(false);
        }),
    [page, pageSize],
  );

  useOnce(() => {
    const refreshData = () => {
      setIsFetching(true);
      handleGetServices();
    };

    document.addEventListener(EVENTS.REFRESH_DATA, refreshData);

    return () => {
      document.removeEventListener(EVENTS.REFRESH_DATA, refreshData);
    };
  });

  useEffect(() => {
    setIsFetching(true);
    handleGetServices();
  }, [page, pageSize]);

  const handlePageChange = (nextPage: number) => {
    queryParams.set("page", String(nextPage));
    replaceQuery();
  };

  const renderToggle = (props: any, ref: any) => {
    return (
      <button className={styles.toggleBtn} {...props} ref={ref}>
        <IconMore />
      </button>
    );
  };

  return (
    <>
      <Scrollbar>
        <Table
          className={styles.table}
          header={
            <Table.Row>
              <Table.Cell>Название</Table.Cell>
              <Table.Cell>ФИО владельца</Table.Cell>
              <Table.Cell>Номер телефона</Table.Cell>
              <Table.Cell>Адрес</Table.Cell>
              <Table.Cell>Поддомен</Table.Cell>
            </Table.Row>
          }
          isEmpty={!services.length}
          isFetching={isFetching}
        >
          {services.map((service) => (
            <Table.Row key={service._id}>
              <Table.Cell>
                <NavLink
                  className={styles.tableUser}
                  to={`/agents/${service._id}`}
                >
                  {service.companyName}
                </NavLink>
              </Table.Cell>
              <Table.Cell>{getUserFullName(service.account)}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>{service.address}</Table.Cell>
              <Table.Cell>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  columnGap="xl"
                >
                  {service.domainName}
                  <Dropdown renderToggle={renderToggle} placement="leftStart">
                    <Dropdown.Item onClick={() => onEdit(service)}>
                      Редактировать
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => onRemove(service)}>
                      Удалить
                    </Dropdown.Item>
                  </Dropdown>
                </Flex>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Scrollbar>
      <If condition={Boolean(services.length)}>
        <Pagination
          activePage={page}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
        />
      </If>
    </>
  );
};

export default ServiceTableList;
