import { useState } from "react";
import Header from "@/components/Header";
import ServiceTableList from "../ServiceTableList";
import Button from "@/components/UI/Button";
import IconAdd from "@/components/Icons/IconAdd";
import Flex from "@/components/UI/Flex";
import Modal from "@/components/UI/Modal";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalBody from "@/components/UI/Modal/ModalBody";
import ServiceForm from "@/features/Service/components/ServiceForm";
import { If } from "@/components/ConditionalRendering/If";
import styles from "./styles.module.scss";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import { removeService } from "@/features/Service/services";
import { toast } from "react-toastify";
import { dispatchEvent } from "@/utils/event";
import { EVENTS } from "@/constants";
import { CarServiceOwnerEntity } from "@/features/Service/types";

const ServiceView = () => {
  const [service, setService] = useState<CarServiceOwnerEntity | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  const [showFormModal, setShowFormModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleShowFormModal = () => {
    setShowFormModal(true);
  };

  const handleCloseFormModal = () => {
    setShowFormModal(false);
    setService(null);
  };

  const handleShowRemoveModal = () => {
    setShowRemoveModal(true);
  };

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
    setService(null);
  };

  const handleEdit = (item: CarServiceOwnerEntity) => {
    setService(item);
    handleShowFormModal();
  };

  const handleRemove = (item: CarServiceOwnerEntity) => {
    setService(item);
    handleShowRemoveModal();
  };

  const handleRemoveService = async () => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await removeService(service?._id as string);
      dispatchEvent(EVENTS.REFRESH_DATA);
      toast.success("Автосервис удален");
      handleCloseRemoveModal();
    } catch (err) {
      toast.error("Ошибка удаления автосервиса");
      setIsFetching(false);
    }
  };

  return (
    <div className={styles.container}>
      <Header title="Автосервисы">
        <Button endIcon={IconAdd} onClick={handleShowFormModal}>
          Автосервисы
        </Button>
      </Header>

      <ServiceTableList onEdit={handleEdit} onRemove={handleRemove} />

      <If condition={showFormModal}>
        <ServiceForm _id={service?._id} onClose={handleCloseFormModal} />
      </If>

      <Modal
        className={styles.modal}
        isOpen={showRemoveModal}
        onClose={handleCloseRemoveModal}
      >
        <ModalHeader>
          <h2>Удалить автосервис?</h2>
        </ModalHeader>
        <ModalBody>
          <p className={styles.modalTitle}>
            Вы уверены, что хотите удалить автосервис?
            <br /> Все данные о нем будут утеряны.
          </p>
        </ModalBody>
        <ModalFooter>
          <Flex alignItems="center">
            <Button
              disabled={isFetching}
              isLoading={isFetching}
              onClick={handleRemoveService}
            >
              Удалить
            </Button>
            <Button
              disabled={isFetching}
              onClick={handleCloseRemoveModal}
              variant="secondary"
            >
              Отмена
            </Button>
          </Flex>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ServiceView;
