import { UnauthorizedLayoutProps } from "./types";
import IconLogo from "@/components/Icons/IconLogo";
import type { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

const UnauthorizedLayout = ({
  children,
}: PropsWithChildren<UnauthorizedLayoutProps>) => {
  return (
    <div className={styles.unauthorizedLayout}>
      <header className={styles.header}>
        <IconLogo />
      </header>
      <main className={styles.main}>{children}</main>
    </div>
  );
};

export default UnauthorizedLayout;
