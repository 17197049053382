import Flex from "@/components/UI/Flex";
import Button from "@/components/UI/Button";
import Modal from "@/components/UI/Modal";
import { Form, Formik } from "formik";
import Input from "@/components/UI/Input";
import { useEffect, useState } from "react";
import { dispatchEvent } from "@/utils/event";
import { CarServiceOwnerEntity } from "../../types";
import { serviceInitialValue, FormFields } from "./constant";
import { serviceFormSchema } from "./schema";
import Grid from "@/components/UI/Grid";
import type { ServiceFormProps } from "./types";
import InputPhone from "@/components/UI/InputPhone";
import { createService, getService } from "@/features/Service/services";
import { toast } from "react-toastify";
import { EVENTS } from "@/constants";
import IconLoading from "@/components/Icons/IconLoading";
import ModalHeader from "@/components/UI/Modal/ModalHeader";
import ModalFooter from "@/components/UI/Modal/ModalFooter";
import styles from "./styles.module.scss";
import ModalBody from "@/components/UI/Modal/ModalBody";

const ServiceForm = ({ _id, onClose }: ServiceFormProps) => {
  const isEdit = Boolean(_id);
  const [isLoading, setIsLoading] = useState(isEdit);
  const [isFetching, setIsFetching] = useState(false);
  const [service, setService] = useState<CarServiceOwnerEntity>(
    // @ts-ignore
    serviceInitialValue,
  );

  useEffect(() => {
    if (isEdit && _id) {
      getService(_id)
        .then(setService)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isEdit, _id]);

  const handleOnSubmit = async (values: CarServiceOwnerEntity) => {
    if (isFetching) return;

    setIsFetching(true);

    try {
      await createService(values);
      dispatchEvent(EVENTS.REFRESH_DATA);
      toast.success("Автосервис добавлен");
      onClose();
    } catch (e) {
      toast.error("Не удалось добавить автосервис");
      setIsFetching(false);
    }
  };

  return (
    <Modal className={styles.modal} onClose={onClose} isOpen={true}>
      <ModalHeader>
        <h2>{isEdit ? "Просмотр автосервиса" : "Добавить автосервис"}</h2>
      </ModalHeader>

      <Formik
        enableReinitialize
        initialValues={service}
        onSubmit={handleOnSubmit}
        validationSchema={serviceFormSchema}
        validateOnChange={false}
      >
        {({ values, errors, handleChange }) => (
          <Form className={styles.form}>
            <ModalBody>
              {isLoading ? (
                <IconLoading />
              ) : (
                <Grid>
                  <Grid.Item col={12}>
                    <Input
                      disabled={isFetching}
                      error={errors[FormFields.CompanyName]}
                      label="Название"
                      name={FormFields.CompanyName}
                      type="text"
                      placeholder="Введете название автосервиса"
                      onChange={handleChange}
                      value={values.companyName}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      disabled={isFetching}
                      label="Фамилия владельца"
                      name={FormFields.LastName}
                      type="text"
                      placeholder="Введите фамилию владельца"
                      onChange={handleChange}
                      value={values.lastName}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      disabled={isFetching}
                      error={errors[FormFields.FirstName]}
                      label="Имя владельца"
                      name={FormFields.FirstName}
                      type="text"
                      placeholder="Введите имя владельца"
                      onChange={handleChange}
                      value={values.firstName}
                    />
                  </Grid.Item>
                  <Grid.Item col={4}>
                    <Input
                      disabled={isFetching}
                      label="Отчество владельца"
                      name={FormFields.MiddleName}
                      type="text"
                      placeholder="Введите отчество владельца"
                      onChange={handleChange}
                      value={values.middleName}
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <Input
                      disabled={isFetching}
                      error={errors[FormFields.Email]}
                      label="Email"
                      name={FormFields.Email}
                      type="email"
                      placeholder="Введите email"
                      onChange={handleChange}
                      value={values.email}
                    />
                  </Grid.Item>
                  <Grid.Item col={6}>
                    <InputPhone
                      disabled={isFetching}
                      error={errors[FormFields.Phone]}
                      label="Номер телефона"
                      name={FormFields.Phone}
                      placeholder="Введите номер телефона"
                      onChange={handleChange}
                      value={values.phone}
                    />
                  </Grid.Item>
                  <Grid.Item col={12}>
                    <Input
                      disabled={isFetching}
                      error={errors[FormFields.DomainName]}
                      label="Поддомен"
                      name={FormFields.DomainName}
                      type="text"
                      placeholder="Введите поддомен"
                      onChange={handleChange}
                      value={values.domainName}
                    />
                  </Grid.Item>
                </Grid>
              )}
            </ModalBody>
            <ModalFooter>
              <Flex alignItems="center" className={styles.modalFooter}>
                <Button
                  isLoading={isFetching}
                  disabled={isFetching}
                  type="submit"
                >
                  Сохранить
                </Button>
                <Button
                  disabled={isFetching}
                  onClick={onClose}
                  variant="secondary"
                >
                  Отмена
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ServiceForm;
