import cn from "classnames";
import { Menu, NavigationToggleProps } from "./types";
import { useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "@/components/UI/Button";
import IconArrowDown from "@/components/Icons/IconArrowDown";
import { isCurrentPath } from "@/components/Navigation/utils";
import styles from "./styles.module.scss";
import IconArrowUp from "@/components/Icons/IconArrowUp";

const NavigationToggle = ({ item }: NavigationToggleProps) => {
  const { pathname } = useLocation();
  const [isOpened, setIsOpened] = useState(false);

  const Icon = item.icon;

  const opened = useMemo(
    () => isCurrentPath(item.path, pathname),
    [item.path, pathname],
  );

  const handleToggle = () => {
    setIsOpened((prev) => !prev);
  };

  const renderMenu = (item: Menu) =>
    item?.submenu?.map(({ icon: Icon, path, title }) => (
      <li key={item.path}>
        <NavLink
          state={{
            title,
          }}
          className={({ isActive }) =>
            cn(styles.navLink, isActive && styles.navSubLinkActive)
          }
          to={path}
        >
          <div className={styles.navRow}>
            {Icon && <Icon />}
            {title}
          </div>
        </NavLink>
      </li>
    ));

  return (
    <li className={styles.navItem}>
      <div className={cn(styles.navRow, opened && styles.navRowOpened)}>
        <Button
          align="left"
          className={styles.navToggleButton}
          startIcon={Icon}
          endIcon={isOpened ? IconArrowUp : IconArrowDown}
          fullWidth
          onClick={handleToggle}
          variant="empty"
        >
          {item.title}
        </Button>
      </div>
      {isOpened && (
        <ul className={cn(styles.navList, styles.navListSubmenu)}>
          {renderMenu(item)}
        </ul>
      )}
    </li>
  );
};

export default NavigationToggle;
