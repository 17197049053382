import { NavLink } from "react-router-dom";
import { IconProps } from "@/components/Icons/types";
import IconLogo from "@/components/Icons/IconLogo";
import styles from "./styles.module.scss";

const Logo = ({ stroke = "white" }: IconProps) => {
  return (
    <div className={styles.logo}>
      <NavLink className={styles.logoLink} to="/">
        <IconLogo />
      </NavLink>
    </div>
  );
};

export default Logo;
