import Pages from "@/components/Pages";
import useCheckAuth from "@/hooks/useCheckAuth";
import { ToastContainer } from "react-toastify";

const App = () => {
  useCheckAuth();

  return (
    <>
      <Pages />
      <ToastContainer />
    </>
  );
};

export default App;
