import TableCell from "./TableCell";
import { TableRowProps, TableCellProps } from "./types";
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
} from "react";
import styles from "./styles.module.scss";

const TableRow = ({ children }: PropsWithChildren<TableRowProps>) => {
  const enhancedChildren = Children.map(children, (child) => {
    if (!isValidElement(child) || child.type !== TableCell) {
      return child;
    }

    return cloneElement(child, {} as TableCellProps);
  });

  return <tr className={styles.row}>{enhancedChildren}</tr>;
};

export default TableRow;
